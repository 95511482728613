import instance from '../services/axios';

export const login = async(data) => {
  return instance.post(`audit/auth/login`, data);
}

export const getUser = async() => {
  const token = localStorage.getItem('token')
  return instance.get(`audit/auth/user`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });
}