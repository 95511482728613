import React, { useState, useEffect, useCallback } from 'react';
import { AppProvider } from './providers/App';
import { useRoutes } from 'react-router-dom';
import { useAuth } from './hooks';
import routes from './routes';
import './App.scss';
import './pages/Styles.scss';

function App() {

  const { store, getUser } = useAuth();

  const [renderInitial, setRenderInitial] = useState(false);

  // const fetchData = useCallback(async() => {
    
    
    
  // }, [])
  
  useEffect(() => {
    const fetchData = async() => {
      try {
        await getUser()
        setRenderInitial(true)
        // if(JSON.stringify(user) !== '{}') {
        // } else {
        //   setTimeout(() => {
        //     setRenderInitial(true)
        //   }, 700)
        // }
      } catch (error) {
        console.error(error)
        setRenderInitial(true)
      }
    }
    fetchData()
  }, [])

  const routing = useRoutes(routes(store.user));

  return (
    <>
      {
        renderInitial && (
          <AppProvider>
            { routing }
          </AppProvider>
        )
      }
    </>
  );
}

export default App;
