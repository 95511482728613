import instance from './axios';
import { store } from '../redux/configStore';

export const getReports = async() => {
  const { user } = await store.getState();
  return instance.get(`reports`, {
    headers: {
      'Authorization': `Bearer ${user?.token}`
    }
  });
}

export const approveReport = async(id) => {
  const { user } = await store.getState();
  return instance(`report/approved/${id}`, {
    method: 'put',
    headers: {
      'Authorization': `Bearer ${user?.token}`
    }
  });
}

export const rejectedReport = async(id) => {
  const { user } = await store.getState();
  return instance(`report/rejected/${id}`, {
    method: 'put',
    headers: {
      'Authorization': `Bearer ${user?.token}`
    }
  });
}