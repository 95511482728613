import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../hooks';
import { LogoWhite } from '../assets/images';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

export const Header = () => {

  const { store, logout } = useAuth();

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <header className="header">
        <div className='container-btn-menu'>
          <FontAwesomeIcon className='color-white btn-menu' icon={faBars} />
        </div>
        <div className='profile' onClick={handleOpenUserMenu}>
          <div className='image'>
            <img src={LogoWhite} alt="Img Profile" />
          </div>
          <span className='color-white'>{ store.user?.name }</span>
        </div>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem onClick={handleCloseUserMenu}>
            <Typography textAlign="center" onClick={logout}>Logout</Typography>
          </MenuItem>
        </Menu>
      </header>
    </>
  )
}