import React, { useState } from 'react';
import { Button, Input } from '../components';
import { Alert, Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import { signInEmailPassword } from '../services';
import { LogoWhite2 } from '../assets/images';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks';

export const Login = () => {

  const { login } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  // ALERT
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [typeAlert, setTypeAlert] = useState('error');

  const submit = async(event) => {
    event.preventDefault();
    try {
      await login(email, password);
    } catch(err) {
      console.log(err)
      setMessageAlert(err.message);
      setTypeAlert('error');
      setShowAlert(true);
    }
  }

  return (
    <>
      <main className='login'>
      <img className='logo' src={LogoWhite2} alt="Logo" />
        <form onSubmit={submit} className="form">
          <h1 className='color-white title'>Iniciar Sesión</h1>
          <Input type='text' name='text' placeholder={'Correo'} onChange={({target}) => setEmail(target.value)} value={email} />
          <Input type='password' name='new-password' placeholder={'Contraseña'} onChange={({target}) => setPassword(target.value)} value={password} />
          <Button label="Ingresar" type='submit'/>
          <Link to='/recovery'>Olvidé mi contraseña</Link>
          
          <Collapse in={showAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity={typeAlert}
              className="alert"
            >
              {messageAlert}
            </Alert>
          </Collapse>
        </form>
      </main>
    </>
  )
}