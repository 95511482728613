import React, { useState } from 'react';
import { faAngleDown, faAngleRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Collapse } from '@mui/material';

const Category = ({data, index, activeCategory, setActiveCategory}) => {

  const activeCategoryFn = () => {
    if(activeCategory) {
      setActiveCategory(NaN);
    } else {
      setActiveCategory(index);
    }
  }

  return (
    <>
      <li className={`category ${activeCategory ? 'category-active' : ''}`} onClick={activeCategoryFn} >
        {
          data.directories.length > 0 ? (
            <span className='text'>
              { data.name }
              <FontAwesomeIcon className='icon' icon={activeCategory ? faAngleRight : faAngleDown}/>
            </span>
          ) : (
            <Link key={`directory-${index}`} to={`${data?.route}`} className='directory text'>
              <FontAwesomeIcon icon={faCircle} className="icon" />
              {data.name}
            </Link>
          )
        }
        {
          data.directories.length > 0 && (
            <Collapse in={activeCategory}>
              <ul className='directories'>
                {
                  data.directories?.map((element, index) => (
                    <Link key={`directory-${index}`} to={`${element.path}`} className='directory'>
                      <FontAwesomeIcon icon={faCircle} className="icon" />
                      {element.name}
                    </Link>
                  ))
                }
              </ul>
            </Collapse>
          )
        }
      </li>
    </>
  );
};

export const Sidebar = () => {

  const [activeCategory, setActiveCategory] = useState(NaN);

  return (
    <div className='sidebar'>
      <h3 className='logo'>Greenbi</h3>
      <ul className='categories'>
        {
          menu.map((element, index) => (
            <Category key={`Category-${index}`} data={element} index={index} activeCategory={activeCategory === index} setActiveCategory={setActiveCategory} />
          ))
        }
      </ul>
    </div>
  );
};

const menu = [
  {
    id: 1,
    name: 'Pendientes',
    route: 'pending',
    directories: [],
    //   {
    //     id: 1,
    //     name: 'Usuarios',
    //     path: 'users',
    //   }
    // ]
  },
  {
    id: 2,
    name: 'Rechazados',
    route: 'rejected',
    directories: []
  },
  {
    id: 3,
    name: 'Aprobados',
    route: 'approved',
    directories: []
  }
]