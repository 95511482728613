/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { Check, Close } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import { useReports } from "../hooks";

export const Row = ({data, index, currentPage}) => {

  const { changeStatus } = useReports();

  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');

  const onConfirm = (param) => {
    setType(param)
    setOpen(true)
  }
  
  const onSubmit = async() => {
    const res = await changeStatus(data.data.ICodigo, type)
    if(res) {
      setOpen(false)
    }
  }

  return (
    <>
      <tr>
        {
          data.row?.map((row, i) => (
            <>
              {
                row === 'buttons' ? (
                  currentPage !== 'approved' && (
                    <td key={`Data ${i}`} className="container-icons">
                      <Check className='icon approve' onClick={() => onConfirm('approved')} />
                      { currentPage !== 'rejected' && <Close className='icon rejected' onClick={() => onConfirm('rejected')} /> }
                    </td>
                  )
                ) : row === 'link' ? (
                  <td key={`Data ${i}`}>
                    <a href={`${process.env.REACT_APP_URL_REPORT}/report/${data.data.uid}`} target="_blank">Ver reporte</a>
                  </td>
                ) : (
                  <td key={`Data ${i}`}>{ row }</td>
                )
              }
            </>
          ))
        }
        <Dialog
          key={`Dialog ${index}`}
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirmación"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Estás seguro que quieres { type === 'approved' ? 'aprobar' : type === 'rejected' && 'rechazar'} este reporte?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancelar</Button>
            <Button onClick={onSubmit}>
              { type === 'approved' ? 'Aprobar' : type === 'rejected' && 'Rechazar'}
            </Button>
          </DialogActions>
        </Dialog>
      </tr>
    </>
  )
}