import React, { useState, useEffect } from 'react';
import { Button, Input } from '../components';
import { Alert, Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import { signInEmailPassword } from '../services';
import { useDispatch } from "react-redux";
import { setIsLogging } from "../redux/actions";
import { LogoWhite } from '../assets/images';
import { Link } from 'react-router-dom';

export const Recovery = () => {
  
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  
  // ALERT
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [typeAlert, setTypeAlert] = useState('error');
  

  const submit = async(event) => {
    event.preventDefault();
    try {
      // const { user } = await signInEmailPassword(email);
      // if(user.emailVerified) {
      //   dispatch(setIsLogging(1))
      // } else {
      //   dispatch(setIsLogging(2))
      // }
    } catch(err) {
      console.error(err.code);
      if(err.code === 'auth/wrong-password') {
        setMessageAlert('Contraseña erronea');
        setTypeAlert('error');
        setShowAlert(true);
      } else if(err.code === 'auth/too-many-requests') {
        setMessageAlert('Excediste el maximo de solicitudes');
        setTypeAlert('error');
        setShowAlert(true);
      } else if(err.code === 'auth/user-not-found') {
        setMessageAlert('Este correo no existe en nuestra base de datos');
        setTypeAlert('error');
        setShowAlert(true);
      }
    }
  }

  return (
    <>
      <main className='recovery'>
      <img className='logo' src={LogoWhite} alt="Logo" />
        <form onSubmit={submit} className="form">
          <h1 className='title'>Recordar Contraseña</h1>
          <h3 className='subtitle'>Ingresar datos para verificación de la cuenta</h3>
          <div className='border'></div>
          <Input label={'Correo Electrónico'} type='text' name='text' placeholder={'Correo'} onChange={({target}) => setEmail(target.value)} value={email} />
          <Button label="Verificar" type='submit'/>
          <Link to='/login'>Iniciar sesion</Link>
          
          <Collapse in={showAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity={typeAlert}
              className="alert"
            >
              {messageAlert}
            </Alert>
          </Collapse>
        </form>
      </main>
    </>
  )
}