import React, { useEffect, useState } from 'react';
import { Table } from '../components';
import { useReports } from '../hooks';

export const Approved = () => {

  const { store } = useReports();

  const [dataTable, setDataTable] = useState({ head: [], body: [] });

  useEffect(() => {
    const head = ['Nombre Sitio', 'Nombre', 'Correo', 'Mes', 'Año', 'Link'];
    const body = store.reports?.filter(element => element.Status_Auditoria === 'aprobado').map(element => {
      return { row: [element.Nombre_Sitio, element.Nickname, element.Correo, element.Mes, element.Año, 'link', 'buttons'], data: { ...element }};
    });
    const resDataTable = { head, body };
    setDataTable(resDataTable);
  }, [store.reports, store.app.refresh])

  return (
    <>
      <div className='users'>
        <Table data={dataTable} currentPage='approved' />
      </div>
    </>
  )
}