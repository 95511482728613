import { useDispatch, useSelector } from "react-redux";
import { setLoading, setRefresh } from "../redux/actions";

export const useApp = () => {

  const dispatch = useDispatch();
  const store = useSelector((store) => store);

  const showLoading = () => dispatch(setLoading(true));
  const hideLoading = () => dispatch(setLoading(false));

  const refresh = () => {
    console.log(store.app.refresh)
    dispatch(setRefresh(!store.app.refresh))
  };

  return { dispatch, store, showLoading, hideLoading, refresh };
};