import { useDispatch, useSelector } from "react-redux";
import { setLoading, setLogout, setUser } from "../redux/actions";
import { login as loginService, getUser as getUserService } from "../services";

export const useAuth = () => {

  const dispatch = useDispatch();
  const store = useSelector((store) => store);

  const showLoading = () => dispatch(setLoading(true));
  const hideLoading = () => dispatch(setLoading(false));

  const login = async(email, password) => {
    const { data } = await loginService({email, password})
    localStorage.setItem('token', data?.token)
    dispatch(setUser(data))
  };

  const logout = async() => {
    localStorage.removeItem('token')
    dispatch(setLogout())
  };

  const getUser = async() => {
    const { data } = await getUserService()
    localStorage.setItem('token', data?.token)
    dispatch(setUser(data))
  };

  // const newPassword = async(password) => {
  //   try {
  //     await updateNewPasswordUser(password);
  //     dispatch(setIsLogging(1))
  //   } catch(err) {
  //     console.error(err)
  //   }
  // }

  // const signOutFn = async() => {
  //   try {
  //     showLoading()
  //     await signOut();
  //     dispatch(setIsLogging(0))
  //     hideLoading()
  //   } catch(err) {
  //     hideLoading()
  //     console.error(err)
  //   }
  // }

  return { dispatch, store, showLoading, hideLoading, login, logout, getUser };
};