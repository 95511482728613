import React, { useState, useEffect, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { useReports } from '../hooks';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import "./Styles.scss";

export const Layout = () => {

  const { getReports } = useReports();

  const [renderInitial, setRenderInitial] = useState(false);

  const fetchData = useCallback(async() => {
    await getReports()
    setRenderInitial(true)
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])
    
    return (
      <div className="layout">
        <Sidebar />
        <div className='main'>
          <Header />
          <div className='content'>
            {
              renderInitial && <Outlet />
            }
          </div>
        </div>
      </div>
    );
};