import { useDispatch, useSelector } from "react-redux";
import { setReports } from "../redux/actions";
import { getReports as getReportsService, approveReport, rejectedReport } from "../services";
import { useApp } from "./app.hook";

export const useReports = () => {

  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  
  const { refresh } = useApp();

  const getReports = async() => {
    try {
      const { data } = await getReportsService();
      dispatch(setReports(data));
    } catch(err) {
      console.error(err)
    }
  };

  const changeStatus = async(id, type) => {
    try {
      let refReports = [...store.reports]
      const index = refReports.findIndex(element => element.ICodigo === id)
      if(type === 'approved') {
        await approveReport(id)
        refReports[index].Status_Auditoria = 'aprobado'
      } else if(type === 'rejected') {
        await rejectedReport(id)
        refReports[index].Status_Auditoria = 'rechazado'
      }
      refresh()
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  return { dispatch, store, getReports, changeStatus };
};