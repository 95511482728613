import { SET_LOADING, SET_IS_LOGGING, SET_REFRESH } from "../constants";

const initialState = {
  loading: false,
  isLogging: 0,
  refresh: false
};

const appReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {

    case SET_LOADING:
      return { ...state, loading: action.loading };

    case SET_IS_LOGGING:
      return {
        ...state,
        isLogging: action.isLogging,
      };

    case SET_REFRESH:
      return { ...state, refresh: action.refresh };

    default:
      return state;
      
  }

};

export default appReducer;