import { SET_USER, SET_LOGOUT } from "../constants";

const initialState = {};

const userReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {

    case SET_USER:
      return { ...action.user };

    case SET_LOGOUT:
      return initialState;

    default:
      return state;
      
  }

};

export default userReducer;