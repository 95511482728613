import React from 'react';

export const Input = ({ label, type, value, name, onChange, pattern, placeholder, style, maxLength, autoFocus, onFocus = () => {}, onBlur = () => {} }) => {

  return (
    <div className='container-input'>
      { label && <label className='label'>{ label }</label> }
      <input className='input' type={type} style={style} value={value} name={name} onChange={onChange} onFocus={onFocus} onBlur={onBlur} placeholder={placeholder} autoFocus={autoFocus ?? false} pattern={pattern} maxLength={maxLength} autoComplete='new-text' autoCorrect='off' defaultValue={''}/>
    </div>
  )
}