import { SET_REPORTS } from "../constants";

const initialState = [];

const reportsReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {

    case SET_REPORTS:
      return action.reports;

    default:
      return state;
      
  }

};

export default reportsReducer;