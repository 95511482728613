import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Layout } from '../components';
import { Dashboard, Login, Pending, Rejected, Approved, Recovery } from '../pages';

const routes = (user) => [
  {
    path: '/',
    element: <Outlet />,
    children: [
      {
        index: true,
        element: JSON.stringify(user) !== '{}' ? <Navigate to="/admin/dashboard" /> : <Navigate to="/login" />
      },
      {
        path: '/admin',
        element: JSON.stringify(user) !== '{}' ? <Layout /> : <Navigate to="/login" />,
        children: [
          { index: true, element: <Navigate to="/admin/dashboard" /> },
          { path: '/admin/dashboard', element: <Dashboard /> },
          { path: '/admin/pending', element: <Pending /> },
          { path: '/admin/rejected', element: <Rejected /> },
          { path: '/admin/approved', element: <Approved /> },
        ],
      },
      { path: '/login', element: JSON.stringify(user) !== '{}' ? <Navigate to="/admin/dashboard" /> : <Login /> },
      { path: '/recovery', element: JSON.stringify(user) !== '{}' ? <Navigate to="/admin/dashboard" /> : <Recovery/> },
    ],
  }
];

export default routes;