import React, { useEffect, useState } from 'react';
import { Pagination } from './Pagination';
import { Input } from './Input';
import { Search } from '@mui/icons-material';
import { Row } from './Row';
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { months, years } from '../source';

export const Table = ({data, currentPage}) => {

  const [body, setBody] = useState([]);
  const [bodyOriginal, setBodyOriginal] = useState([]);
  const [initial, setInitial] = useState(0);
  const [end, setEnd] = useState(9);

  // Filter
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);

  useEffect(() => {
    setBodyOriginal(data.body);
    
    const date = new Date();
    setMonth(date.getMonth() + 1);
    setYear(date.getFullYear());
    
    const dataFiltered = data.body.filter(element => element.data.Mes === (date.getMonth() + 1) && element.data.Año === date.getFullYear())
    setBody([ ...dataFiltered ])
  }, [data])

  useEffect(() => {
    if(bodyOriginal.length > 0 && month > 0 && year > 0) {
      const dataFiltered = bodyOriginal.filter(element => element.data.Mes === month && element.data.Año === year)
      setBody([ ...dataFiltered ])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month])

  const onSearch = ({target}) => {
    let copyBody = { ...body };
    copyBody = bodyOriginal.filter(element => (element.data.Nombre_Sitio?.toUpperCase().includes(target.value.toUpperCase()) || element.data.Correo?.toUpperCase().includes(target.value.toUpperCase()) || element.data.Nickname?.toUpperCase().includes(target.value.toUpperCase())) && element.data.Mes === month && element.data.Año === year)
    setBody(copyBody)
  }

  const handleChangeMonth = (_, data) => {
    setMonth(data.props.value)
  }

  const handleChangeYear = (_, data) => {
    setYear(data.props.value)
  }

  return (
    <>
      <table className='table'>
        <div className='container-filters'>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-dialog-select-label">Mes</InputLabel>
              <Select
                labelId="demo-dialog-select-label-month"
                id="demo-dialog-select-month"
                defaultValue={month || 2}
                value={month}
                onChange={handleChangeMonth}
                input={<OutlinedInput label="Mes" />}
              >
                {
                  months.map(element => (
                    <MenuItem value={ element.code }>{ element.name }</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel htmlFor="demo-dialog-native">Año</InputLabel>
              <Select
                labelId="demo-dialog-select-label-years"
                id="demo-dialog-select-years"
                defaultValue={year || 2022}
                value={year}
                onChange={handleChangeYear}
                input={<OutlinedInput label="Año" id="demo-dialog-native" />}
              >
                {
                  years.map(element => (
                    <MenuItem value={ element }>{ element }</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
          <div className='search'>
            <Input type="search" onChange={onSearch} />
            <Search className='icon' />
          </div>
        </div>
        <thead>
          <tr>
            {
              data.head?.map((element, index) => (
                <th key={`Head ${index}`}>{ element }</th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            body.map((element, index) => index >= initial && index <= end ? (
                <Row key={`Row-${index}`} data={element} index={index} currentPage={currentPage} />
              ) : <></>
            )
          }
        </tbody>
      </table>
      <Pagination setInitial={setInitial} end={end} setEnd={setEnd} length={body.length} />
    </>
  )
}