import React from 'react';

export const Dashboard = () => {

  return (
    <>
      <h1>Plataforma de Auditoria - Reportes Greenbi</h1>
      <p>Bienvenido a la interfáz de control de reportes de Greenbi, aquí encontrarás los nuevos reportes generados en Power BI y listos para ser auditados. Los módulos disponibles son:</p>
      <div style={{ paddingLeft: '1rem' }}>
        <h2>Pendientes</h2>
        <p>Son todos los reportes listos para ser revisados, que fueron generados en Power BI y que NO han sido enviados al usuario. Este módulo se actualiza diariamente 2 veces al día, a las 7 am y 1 pm trayendo toda la información nueva generada por la analítica. Aquí podra visualizar el reporte en el link <i>ver reporte</i>, buscar un reporte por nombre de usuario o correo, aprobar un reporte haciendo click en el ícono verde de <i>check</i> o rechazarlo con el ícono rojo <i>reject</i></p>
        <h2>Rechazados</h2>
        <p>Todos los reportes que hayan sido revisados manualmente en el módulo anterior y no cumplan con los requisitos técnicos deberán ser rechazados y avisar al área de operaciones y/o analítica, de los errores que presenta el mismo con el fin de realizar las correcciones pertinentes.

        Una vez un reporte rechazado haya sido corregido, la plataforma detectará los cambios y en la siguiente ejecución programada lo actualizará, este reporte quedará listo para ser revisado una vez más en el módulo de <i>Pendientes</i> y el proceso vuelve a comenzar.

        Se debe realizar seguimiento de los errores que deben ser corregidos en cada reporte y si no existen cambios, es decir, persiste el error, este reporte debe ser analizado por el equipo de automatización, para dar solución al incidente.</p>
        <h2>Aprobados</h2>
        <p>Todos los reportes manualmente auditados y que cumplen con las condiciones para ser enviados a los usuarios, pasan a este modulo, aquí solamente se pueden ver los reporte, pero no se puede modificar su estado, diariamente a las 5 pm un proceso automático se encarga de enviar todos estos reportes a los usuarios, este proceso debe enviar un reporte al responsable de operaciones de greenbi informando de los reportes que no se hayan logrado enviar y las razones.

        Si se tiene alguna novedad con un reporte ya aprobado, se debe contactar con el equipo de automatización para revisar el caso.</p>
        <h2>Login</h2>
        <p>El acceso a la plataforma se realiza mediante usuario y contraseña, estas credenciales están a cargo del área de automatización, para solicitar acceso se debe contactar con el jefe de operaciones para que haga la solicitud, una vez procesada les llegará un correo con el usuario y la contraseña que pueden seguir utilizando responsablemente para acceder a la plataforma.</p>
      </div>
    </>
  )
}