/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Pagination = ({setInitial = () => {}, setEnd = () => {}, length, showElements = 9}) => {

    const [pages, setPages] = useState([])
    const [pageInitial, setPageInitial] = useState(0)
    const [pageEnd, setPageEnd] = useState(4)
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        const totalPages = Math.ceil(length / (showElements + 1))
        let resPages = []
        for(let i = 0; i < totalPages; i++) {
            resPages.push(i + 1)
        }
        setPages(resPages)
        setInitial(0)
        setEnd(showElements)
        setPageInitial(0)
        setPageEnd(4)
        setCurrentPage(1)
    }, [length])

    useEffect(() => {
        const resInitial = ((currentPage - 1) * (showElements + 1))
        setInitial(resInitial)
        setEnd(resInitial + showElements)
    }, [currentPage])

    const nextPage = () => {
        const current = currentPage + 1
        setCurrentPage(current)
        if(currentPage > 2 && currentPage < (pages.length - 2)) {
            const currentPageInitial = pageInitial + 1
            const currentPageEnd = pageEnd + 1
            setPageInitial(currentPageInitial)
            setPageEnd(currentPageEnd)
        } 
    }
    
    const previousPage = () => {
        const current = currentPage - 1
        setCurrentPage(current)
        if(currentPage > 3 && currentPage < (pages.length - 1)) {
            const currentPageInitial = pageInitial - 1
            const currentPageEnd = pageEnd - 1
            setPageInitial(currentPageInitial)
            setPageEnd(currentPageEnd)
        }
    }

    const changePageNumber = (number) => {
        setCurrentPage(number)
        if(number < 3) {
            setPageInitial(0)
            setPageEnd(4)
        } else if(number > (pages.length - 2)) {
            setPageInitial(pages.length - 5)
            setPageEnd(pages.length - 1)
        } else {
            setPageInitial(number - 3)
            setPageEnd(number + 1)
        }
    }

    return (
        <>
            {
                pages.length > 0 && (
                    <>
                        <div className="pagination">
                            <div className='contain-pagination'>
                                { pages.length > 1 && currentPage > 1 && <FontAwesomeIcon className='box previous-page' icon={faAngleLeft} onClick={previousPage}/> }
                                {
                                    pages.map((element, index) => index >= pageInitial && index <= pageEnd ? (
                                            <span key={`Page-${index}`} className={`box ${currentPage == element ? 'box-active' : ''}`} onClick={() => changePageNumber(element)}>{element}</span>
                                        ) : index === 0 && currentPage > 3 ? (
                                            <>
                                                <span key={`Page-${index}`} className={`box ${currentPage == element ? 'box-active' : ''}`} onClick={() => changePageNumber(element)}>{element}</span>
                                                <span key={`...-${index}`} className="box box-points">...</span>
                                            </>
                                        ) : index === (pages.length - 1) ? (
                                            <>
                                                <span key={`Page-${index}`} className="box box-points">...</span>
                                                <span key={`...-${index}`} className={`box ${currentPage == element ? 'box-active' : ''}`} onClick={() => changePageNumber(element)}>{element}</span>
                                            </>
                                        ) : <></>
                                    )
                                }
                                { pages.length > 1 && currentPage < pages.length && <FontAwesomeIcon className='box next-page' icon={faAngleRight} onClick={nextPage}/> }
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
}